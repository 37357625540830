import React from 'react';
import StatusCard from '../StatusCard/StatusCard';
import './DashboardPanel.css';

function DashboardPanel({ title, style, row = false, status_card = undefined, status_card_title = '', children }) {

  return (
    <div className='dashboard-panel' style={style}>
      <div className='header'>
        <div className='title'>
          {title}
        </div>
        {status_card && <StatusCard title={status_card_title} status={status_card}></StatusCard>}
      </div>
      <div className={row ? 'content content-row': 'content'}>{children}</div>
    </div>
  );
}

export default DashboardPanel;
