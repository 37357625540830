import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend
);

const MultiLineGraph = ({ labels = [], datasets = [], XLabel = "", YLabel = "" }) => {
    const data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: XLabel,
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: YLabel,
                },
                ticks: {
                    callback: function (value) {
                        return value + ' ms';
                    }
                },
                beginAtZero: true,
            }
        }
    };

    return <Line data={data} options={options} />;
};

export default MultiLineGraph;