import AxiosFactory from "./Axios";
const pako = require("pako");

const baseURL = "https://dashboards.dparadig.com:8099";

function recoverOriginalContent(compressedBase64) {
  try {
    const compressedData = atob(compressedBase64);
    const compressedArray = new Uint8Array(compressedData.length);
    for (let i = 0; i < compressedData.length; i++) {
      compressedArray[i] = compressedData.charCodeAt(i);
    }
    const decompressedArray = pako.inflate(compressedArray);
    const originalString = new TextDecoder().decode(decompressedArray);
    return originalString;
  } catch (error) {
    console.error("Error recovering original content:", error);
    return null;
  }
}

export async function getData() {
  let response;
  const checkmkApi = AxiosFactory(baseURL);
  response = await checkmkApi.get("cns/data");
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export function getSoapData() {
  const res = {
    arrayResponseTime: [
      {
        name: "BFF SOAP",
        status: "UNK",
        time: "",
      },
      {
        name: "Consorcio SOAP",
        status: "UNK",
        time: "",
      },
      {
        name: "Devetel",
        status: "UNK",
        time: "",
      },
      {
        name: "Webpay",
        status: "UNK",
        time: "",
      },
    ],
    arrayServiceState: [
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
      {
        name: "Servicio 1",
        status: "UNK",
      },
    ],
    arrayMachineState: [
      { name: "Maquina 1", status: "UNK" },
      { name: "Maquina 2", status: "UNK" },
      { name: "Maquina 3", status: "UNK" },
      { name: "Maquina 4", status: "UNK" },
      { name: "Maquina 5", status: "UNK" },
      { name: "Maquina 6", status: "UNK" },
      { name: "Maquina 7", status: "UNK" },
      { name: "Maquina 8", status: "UNK" },
      { name: "Maquina 9", status: "UNK" },
      { name: "Maquina 10", status: "UNK" },
      { name: "Maquina 11", status: "UNK" },
      { name: "Maquina 12", status: "UNK" },
    ],
    arrayDatabaseState: [
      {
        category: "Ventas",
        type: "última hora",
        service: "Cantidad de ventas",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_PRIMA_HORA",
        column: 1,
      },
      {
        category: "Ventas",
        type: "día en curso",
        service: "Cantidad de ventas",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_PRIMA_DIA",
        column: 1,
      },
      {
        category: "Ventas",
        type: "mes en curso",
        service: "Cantidad de ventas",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_PRIMA_MES",
        column: 1,
      },
      {
        category: "Seguros",
        type: "última hora",
        service: "Seguros contratados",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_Q_HORA",
        column: 1,
      },
      {
        category: "Seguros",
        type: "día en curso",
        service: "Seguros contratados",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_Q_DIA",
        column: 2,
      },
      {
        category: "Seguros",
        type: "mes en curso",
        service: "Seguros contratados",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_Q_MES",
        column: 2,
      },
    ],
  };
  return res;
}
