import axios from 'axios';
import handleError from './errorHandler.rest';

export default function AxiosFactory(baseURL, path = '') {
  const axiosInstance = axios.create({
    baseURL: baseURL + path,
    headers: {
      'Content-Type': 'application/json',
      'API-Token': '6lRLiOeUmVoCp5HuXzUJc0hyCiTwR_t7',
    },
  });
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      handleError(error.response && error.response.status);
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}
