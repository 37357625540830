import React from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import DashboardPanel from "../../components/DashboardPanel/DashboardPanel";
import CardInfo from "../../components/CardInfo/CardInfo";
import MultiLineGraph from "../../components/MultiLineGraph/MultiLineGraph";
import MultiLineStepChart from "../../components/MultiLineGraph/MultiLineStepChart";
import "./SOAP.css";
import { Flujos } from "../../components/Flujos/Flujos";
import ReactSpeedometer from "react-d3-speedometer"

function SOAP({ style, soapInfo, soapGraphData, soapSynthData }) {
  return (
    <section className="soap-view" style={style}>
      <Toolbar title={`Monitoreo Integral SOAP`} />
      <main className="main-container">
        <div className="panel-1">
          <div className="column-1">
            <DashboardPanel
              title="Tiempos de respuesta"
              row={true}
              style={{ width: "100%", padding: "5px", height: "50%" }}
            >
              <div className="border-dashed-grey-blue response-time">
                {soapInfo.arrayResponseTime.map((item, index) => {
                  const card = <CardInfo
                    key={`response-time-${index}`}
                    title={item.name}
                    status_card={item.status}
                    status_card_title={item.time}
                    style={{height: "46px"}}
                  />
                  if (item.link) {
                    return (
                      <a href={item.link}
                        style={{ width: "100%" }}
                        target="_blank"
                      >{card}</a>
                    )
                  }
                  return card;
                })}
              </div>
              <div
                className="border-dashed-grey-blue"
                style={{ width: "70%", }}
              >
                <MultiLineGraph labels={soapGraphData.labels} datasets={soapGraphData.datasets} XLabel='' YLabel='' />
              </div>
            </DashboardPanel>

            <DashboardPanel
              title="Sintéticos"
              style={{ height: "50%" }}
            >
              <MultiLineStepChart labels={soapSynthData.labels} datasets={soapSynthData.datasets} />
            </DashboardPanel>
          </div>
          <DashboardPanel title="Estados de servicios" style={{ width: "40%" }}>
            <div style={{ overflowY: "auto" }}>
              <div className="border-dashed-grey-blue" style={{ padding: 10 }}>
                {soapInfo.arrayServiceState.map((item, index) => {
                  const card = (
                    <CardInfo
                      key={`service-state-${index}`}
                      style={{ height: "38px" }}
                      title={item.name}
                      status_card={item.status}
                      status_card_title={item.time}
                    />
                  );
                  const link = item.link;
                  if (link) {
                    return (
                      <a href={link}
                        style={{ width: "100%" }}
                        target="_blank"
                      >{card}</a>
                    )
                  }
                  return card;
                })}
              </div>
            </div>
          </DashboardPanel>

        </div>
        <div className="panel-2">
          <DashboardPanel
            title="Estados de Máquinas"
            style={{ width: "20%" }}
            status_card={soapInfo.arrayMachineState.some((item) => item.status === "CRITICAL") ? "CRITICAL" : "OK"}
          >
            <div
              className="machine-status"
            >
              {soapInfo.arrayMachineState.map((item, index) => {
                const hostName = item.name.split(" ")[0];
                const link = hostName === "C2ME0025" ? "https://onenr.io/0dQeMV6B5Qe" : `https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?host=${hostName}`
                return (
                  <a href={link}
                    style={{ width: "100%" }}
                    target="_blank"
                  >
                    <CardInfo
                      key={`machine-state-${index}`}
                      title={item.name}
                      status={item.status}
                      style={{}}
                    />
                  </a>
                );
              })}
            </div>
          </DashboardPanel>

          {/* Colummn 1 */}
          <DashboardPanel
            title="Base de datos"
            style={{
              height: "100%",
              width: "80%",

            }}
            row={true}
          >
            <div className="database-body">
              {soapInfo.arrayDatabaseState.map((item, index) => {
                return (
                  <a
                    href={`https://nagios.dparadig.com/thruk/cgi-bin/extinfo.cgi?type=2&host=CNSSQL02&service=${item.serviceName}`}
                    target="_blank"
                    key={`db-state-${index}`}
                    style={{ width: "30%", height: "40%", padding: 5 }}
                    className="border-dashed-grey-blue db-sales"
                  >
                    <CardInfo
                      label={`${item.category} ${item.type}`}
                      title={item.service}
                      style={{height: "46px"}}
                      status_card={"NEUTRAL"}
                      status_card_title={item.info}
                      textFormat={item.category === "Ventas" ? "money" : "amount"} />
                  </a>
                );

              })}
            </div>
            <ReactSpeedometer
              value={soapInfo.rate}
              maxValue={60}
              width={300}
              height={180}
              currentValueText="Tasa Transitoria: #{value}"
              currentValuePlaceholderStyle={'#{value}'}
              customSegmentStops={[0, 45, 48,60]}
              textColor={"#8492A9"}
              segmentColors={['#2BDC92', '#F9CC2B', '#ED4C5C']}
            />
          </DashboardPanel>

        </div>
      </main>
    </section>
  );
}

export default SOAP;
