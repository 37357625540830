// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-panel{
    background-color: white;
    border-radius: 10px;
    padding: 0px 5px;
}

.dashboard-panel .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 5px;
}

.dashboard-panel .header .title{
    font-family: Sansation;
    color: #8492A9;
    font-size: 18px;
    font-weight: 700;
    line-height: 20.14px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}



.dashboard-panel .content{
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
}

.dashboard-panel .content.content-row{
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    height: fit-content;
    max-height: calc(100% - 40px);
    overflow-y: auto;
}

.dashboard-panel .content .column{
    width: 48%;
}`, "",{"version":3,"sources":["webpack://./components/DashboardPanel/DashboardPanel.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,gBAAgB;IAChB,kCAAkC;IAClC,8BAA8B;AAClC;;;;AAIA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,mBAAmB;IACnB,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".dashboard-panel{\n    background-color: white;\n    border-radius: 10px;\n    padding: 0px 5px;\n}\n\n.dashboard-panel .header{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 10px 5px;\n}\n\n.dashboard-panel .header .title{\n    font-family: Sansation;\n    color: #8492A9;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 20.14px;\n    letter-spacing: 0.04em;\n    text-align: left;\n    text-underline-position: from-font;\n    text-decoration-skip-ink: none;\n}\n\n\n\n.dashboard-panel .content{\n    display: flex;\n    flex-direction: column;\n    height: calc(100% - 40px);\n}\n\n.dashboard-panel .content.content-row{\n    display: flex;\n    flex-flow: wrap;\n    justify-content: space-around;\n    height: fit-content;\n    max-height: calc(100% - 40px);\n    overflow-y: auto;\n}\n\n.dashboard-panel .content .column{\n    width: 48%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
