export const soapHosts = [
    { name: "pconvm226", datasource: "nagios" },
    { name: "pconvm227", datasource: "nagios" },
    { name: "pconvm203", datasource: "nagios" },
    { name: "pconvm238", datasource: "nagios" },
    { name: "pconvm239", datasource: "nagios" },
    { name: "PCON199", datasource: "nagios" },
    { name: "CNSSQL02", datasource: "nagios" },
    { name: "pconvm271", datasource: "nagios" },
    { name: "pconvm272", datasource: "nagios" },
    { name: "pconvm222", datasource: "nagios" },
    { name: "pconvm223", datasource: "nagios" },
    { name: "C2ME0025", datasource: "nr" },
    { name: "mnvm2011p", datasource: "nagios" },
]



export const soapServices = [
    { name: "ws-validacion-seguros-vehiculos", host: ["JMX_wsGenerales03_pconvm271_28690", "JMX_wsGenerales04_pconvm272_28691"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=JMX_wsGenerales03_pconvm271_28690&dfl_s1_type=host&dfl_s1_val_pre=&dfl_s1_op=%3D&dfl_s1_value=JMX_wsGenerales04_pconvm272_28691" },
    { name: "WS_DatosCotizador", host: ["JMX_instancia3_pconvm222_28686", "JMX_instancia4_pconvm223_28686"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&update.x=4&update.y=6&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=JMX_instancia3_pconvm222_28686&dfl_s1_type=host&dfl_s1_val_pre=&dfl_s1_op=%3D&dfl_s1_value=JMX_instancia4_pconvm223_28686" },
    { name: "serviciosCotizadorWSP", host: ["JMX_cotizadorWSP01_pconvm271_28686", "JMX_cotizadorWSP02_pconvm272_28686", "JMX_cotizadorWSP03_pconvm271_28688", "JMX_cotizadorWSP04_pconvm272_28688"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=JMX_cotizadorWSP01_pconvm271_28686&dfl_s1_type=host&dfl_s1_val_pre=&dfl_s1_op=%3D&dfl_s1_value=JMX_cotizadorWSP02_pconvm272_28686&dfl_s2_type=host&dfl_s2_val_pre=&dfl_s2_op=%3D&dfl_s2_value=JMX_cotizadorWSP03_pconvm271_28688&dfl_s3_type=host&dfl_s3_val_pre=&dfl_s3_op=%3D&dfl_s3_value=JMX_cotizadorWSP04_pconvm272_28688" },
    { name: "Webservices SOAP", host: ["JMX_instancia9_pconvm222_28688", "JMX_instancia10_pconvm223_28688"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&update.x=5&update.y=5&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=JMX_instancia9_pconvm222_28688&dfl_s1_type=host&dfl_s1_val_pre=&dfl_s1_op=%3D&dfl_s1_value=JMX_instancia10_pconvm223_28688" },
    { name: "ServiciosFirmaElectronica_WP", host: ["JMX_instancia13_pconvm222_28690", "JMX_instancia14_pconvm223_28690"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=JMX_instancia13_pconvm222_28690&dfl_s1_type=host&dfl_s1_val_pre=&dfl_s1_op=%3D&dfl_s1_value=JMX_instancia14_pconvm223_28690" },
    { name: "Devetel", link: "https://onenr.io/0BQ1EAplbQx" },
    { name: "BFF SOAP", link: "https://onenr.io/07j9L7b2xRO" },
    { name: "Sitio Publico Seguro Automotriz", link: "https://onenr.io/07jb3M0rBQy" },
    { name: "Sintetico DVU", host: ["Flujos_DVU-Consorcio_container"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=Flujos_DVU-Consorcio_container",serviceName:"Flujo DVU Flujo SOAP" },
    { name: "Sintetico New Relic", link: "https://onenr.io/0bRK39a6gRE" },
    { name: "Notificación SOAP", host: ["PCON199"], datasource: "nagios", link: "https://nagios.dparadig.com/thruk/cgi-bin/status.cgi?nav=&hidesearch=0&hidetop=&dfl_columns=host_name%2Chost_address%2Cdescription%2Cstate%2Clast_check%2Cduration%2Ccurrent_attempt%2Cplugin_output&style=detail&update.x=9&update.y=7&dfl_s0_type=host&dfl_s0_val_pre=&dfl_s0_op=%3D&dfl_s0_value=PCON199",serviceName:"Proceso: notificacion_soap.exe" },
];

export const soapBD = [
    {
        category: "Ventas",
        type: "última hora",
        service: "Cantidad de ventas",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_PRIMA_HORA",
        column: 1,
    },
    {
        category: "Ventas",
        type: "día en curso",
        service: "Cantidad de ventas",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_PRIMA_DIA",
        column: 1,
    },
    {
        category: "Ventas",
        type: "mes en curso",
        service: "Cantidad de ventas",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_PRIMA_MES",
        column: 1,
    },
    {
        category: "Seguros",
        type: "última hora",
        service: "Seguros contratados",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_Q_HORA",
        column: 1,
    },
    {
        category: "Seguros",
        type: "día en curso",
        service: "Seguros contratados",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_Q_DIA",
        column: 2,
    },
    {
        category: "Seguros",
        type: "mes en curso",
        service: "Seguros contratados",
        status: "UNK",
        serviceName: "SQLServer Query: SOAP_Q_MES",
        column: 2,
    },
]