import React, { useState, useEffect } from 'react';
import Toolbar from '../../components/Toolbar/Toolbar';
import DashboardPanel from '../../components/DashboardPanel/DashboardPanel';
import CardInfo from '../../components/CardInfo/CardInfo';
import "./PanelEjecutivo.css"
import { wraps } from '../../utils/panel_ejecutivo_data';

function PanelEjecutivo({ style, hosts, togglePanel }) {

    function getContainer(id) {
        const wrap = wraps[id];
        const content = wrap.items.map((item) => {
            if (item.status_card) {
                return <CardInfo title={item.title} status={item.status} style={item.style} status_card={item.status_card} status_card_title={item.status_card_title} label={item.label} redirect={item.redirect} togglePanel={togglePanel}></CardInfo>
            }
            else {
                return <CardInfo title={item.title} status={item.status} style={item.style} label={item.label}></CardInfo>
            }
        });
        const row = wrap.row;

        return <DashboardPanel title={wrap.title} style={wrap.style} row={row} status_card={wrap.status_card} status_card_title={wrap.status_card_title}>{content}</DashboardPanel>
    }

    return (
        <section className="panel-view" style={style}>
            <Toolbar title={`Panel Ejecutivo`} />
            <div className="body">
                <div className='col1'>
                    {getContainer("Incidentes")}
                    {getContainer("Servicios")}
                </div>
                <div className='col2'>
                    <div className='custom-row'>
                        {getContainer("Fw")}
                        {getContainer("Servidores")}
                    </div>
                    <div className='custom-row'>
                        {getContainer("SSO1")}
                        {getContainer("SSO2")}
                    </div>
                    {getContainer("Sintetico", true)}
                    <div className='custom-row'>
                        {getContainer("PAM")}
                        {getContainer("BBDD")}
                    </div>
                </div>
                <div className='col3'>
                    {getContainer("Sucursales")}
                    {getContainer("Central")}
                    {getContainer("Enlaces")}
                </div>
            </div>
        </section>
    )
}

export default PanelEjecutivo;