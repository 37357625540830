import React, { useState, useEffect, useRef } from 'react'
import { PanelProps } from '@grafana/data'
import { SimpleOptions } from 'types'
import './MainView.css'
//@ts-ignore
import { getData, getSoapData } from '../../rest/backend.js'

// @ts-ignore
import { nagios_host } from 'utils/hostUtilNagios.js'
// @ts-ignore
import { icons } from 'utils/dictionaryIcons.js'
// @ts-ignore
import { global_states } from 'utils/dictionaryStates.js'
// @ts-ignore
import { soapHosts, soapServices, soapBD } from 'utils/soapData.js'
// @ts-ignore
import { getFlujosSeguros } from '../../api/datasource'
// @ts-ignore
import { getHosts, getHostsServices } from '../../rest/nagios.js'
// @ts-ignore
import PanelEjecutivo from '../../views/PanelEjecutivo/PanelEjecutivo'
// @ts-ignore
import SistemaDeSeguros from '../../views/SistemaDeSeguros/SistemaDeSeguros'
// @ts-ignore
import SOAP from '../../views/SOAP/SOAP'

interface Props extends PanelProps<SimpleOptions> {
    options: any
    data: any
    width: number
    height: number
}

export const MainView: React.FC<Props> = ({ options, data, width, height }) => {
    const containerRef: any = useRef(null);
    const [flujosSeguros, setFlujosSeguros] = useState([])
    const [soapData, setSoapData] = useState(getSoapData())
    const [soapGraphData, setSoapGraphData] = useState<{ labels: any[]; datasets: any[] }>({ labels: [], datasets: [] })
    const [soapSynthData, setSoapSynthData] = useState<{ labels: any[]; datasets: any[] }>({ labels: [], datasets: [] })
    const [hostsCns, setHostsCns] = useState([])
    const [panelDisplay, setPanelDisplay] = useState('SOAP')
    const [panelWraps, setPanelWraps] = useState(JSON.parse(options.json_structure_panel_ejecutivo))

    async function getFlujos() {
        const responseFlujoSeguros = await getFlujosSeguros()
        setFlujosSeguros(responseFlujoSeguros)
    }

    async function getInfoSoap() {
        const resSoap = await getSoapData()
        setSoapData(resSoap)
    }

    useEffect(() => {
        getFlujos()
        //getInfoSoap();
    }, [data])

    useEffect(() => {
        function adjustScale() {
          // Tamaño actual de la ventana
          const screenWidth = width;
          const screenHeight = height;
    
          // Tamaño base del diseño
          const baseWidth = 1920;
          const baseHeight = 1000;
    
          // Calcula las escalas para ancho y alto
          const scaleX = screenWidth / baseWidth;
          const scaleY = screenHeight / baseHeight;
    
          // Establece un límite superior para la escala
          const maxScale = 1.5; // Este valor puede ser ajustado para pantallas grandes
          const scale = Math.min(scaleX, scaleY, maxScale);
    
          // Aplica la escala al contenedor
          if (containerRef.current) {
            containerRef.current.style.transform = `scale(${scale})`;
            containerRef.current.style.transformOrigin = 'top left'; // Escala desde la esquina superior izquierda
          }
        }
    
        // Llama a la función cuando cambie el tamaño de la ventana
        adjustScale();
    
        // Escucha los cambios de tamaño de la ventana
        window.addEventListener('resize', adjustScale);
    
        // Limpieza de la función en caso de que el componente se desmonte
        return () => window.removeEventListener('resize', adjustScale);
      }, [width, height]);

    function getStatesForServices(data: any, requests: any) {
        const results = []
        for (const { hostName, serviceDescription } of requests) {
            if (!data[hostName]) {
                results.push({
                    hostName,
                    serviceDescription,
                    state: null,
                    error: `Host "${hostName}" no encontrado.`
                })
                continue
            }
            const service = data[hostName].services.find((service: any) => service.description === serviceDescription)
            if (!service) {
                results.push({
                    hostName,
                    serviceDescription,
                    state: null,
                    error: `Servicio "${serviceDescription}" no encontrado para el host "${hostName}".`
                })
                continue
            }
            results.push({
                hostName,
                serviceDescription,
                state: service.state,
                error: null
            })
        }
        return results
    }

    function getRandomColor() {
        return `hsl(${Math.random() * 360}, 70%, 50%)`
    }

    function pnpNagiosReadSynth(csvString: any) {
        const lines = csvString.trim().split('\n');
        const headers = lines[0].split(';').map((header: any) => header.trim());
        const timestampIndex = headers.indexOf('timestamp');
        const stepsFailMaxIndex = headers.indexOf('steps_fail_MAX');
        if (timestampIndex !== -1 && stepsFailMaxIndex !== -1) {
            let dataRows: any = new Map();
            const timeStamps: any = [];
            lines.slice(1).forEach((line: any) => {
                const cells = line.split(';').map((cell: any) => parseFloat(cell.trim()));
                const timestamp = cells[timestampIndex]
                const date = new Date(timestamp * 1000);
                date.setSeconds(0, 0);
                timeStamps.push(date.getTime())
                dataRows.set(date.getTime(), cells[stepsFailMaxIndex] === 0 ? 3 : 2);
            });
            return { rowsMap: dataRows, timeStamps: timeStamps };
        }
        return undefined;
    }

    async function getBackendInfo() {
        const data = await getData()
        if (data) {
            const nagiosData = data.nagios
            const apiMetrics = data.api_metrics;
            const arrayMachineStateTemp: any = [];
            soapHosts.forEach((hostItem: any) => {
                const hostName = hostItem.name;
                if (hostItem.datasource === 'nagios') {
                    const host = nagiosData[hostName]
                    if (host) {
                        return arrayMachineStateTemp.push({ name: `${hostName} (${host.address})`, status: global_states[host.state] });
                    }
                }
                if (hostItem.datasource === 'nr') {
                    const host = apiMetrics[hostName]
                    const alerts = host.hosts.query?.data?.actor?.account?.nrql?.results;
                    let status = 0;

                    alerts.forEach((alert: any) => {
                        if (alert.priority === 'warning' && status === 0) {
                            status = 1;
                        }
                        else if (alert.priority === 'critical') {
                            status = 2;
                        }
                    });

                    return arrayMachineStateTemp.push({ name: hostName, status: global_states[status] });
                }
                return arrayMachineStateTemp.push({ name: hostName, status: 'UNK' });
            })

            const bdSoap = nagiosData['CNSSQL02'] || [];
            const arrayDatabaseSOAPTemp = soapBD.map((dbstate: any) => {
                const nagiosSoapService = bdSoap.services.find((sv: any) => sv.display_name === dbstate.serviceName)
                if (nagiosSoapService) {
                    const perfData = nagiosSoapService.perf_data
                    return {
                        ...dbstate,
                        info: perfData.split('=')[1],
                        status: global_states[nagiosSoapService.state]
                    }
                } else {
                    return dbstate
                }
            })
            const svRate = nagiosData['CNSSQL02'].services.find((sv: any) => sv.display_name === 'SQLServer Query: SOAP_TASA_TRANSITORIA_HORA')
            let rate = 0;
            if (svRate && svRate.perf_data) {
                const perfData = svRate.perf_data.split('=');
                if (perfData.length > 1) {
                    rate = parseInt(perfData[1])
                }
            }
            //Graph data
            let graphResponseTimeEntities: any = {};
            let ServiceEntities: any = {};
            Object.entries(apiMetrics).forEach(([key, service]: any) => {
                if (service.response_time) {
                    graphResponseTimeEntities[key] = service;
                }
                if (service.services) {
                    ServiceEntities[key] = service.services;
                }
            })

            const allTimestamps = [
                ...new Set(
                    Object.values(graphResponseTimeEntities).flatMap((service: any) =>
                        service.response_time?.query?.data?.actor?.account?.nrql?.results.map(
                            (entry: any) => {
                                const date = new Date(entry.beginTimeSeconds * 1000);
                                date.setSeconds(0, 0);
                                return date.getTime();
                            }
                        )
                    )
                )
            ].sort((a, b) => a - b)
            const formattedTimestamps = allTimestamps.map((date) => {
                const formattedTime = new Date(date);
                const hours = formattedTime.getHours();
                const minutes = formattedTime.getMinutes();
                return `${hours}:${String(minutes).padStart(2, '0')}`;
            })

            const datasetsResponseTimes: any = []
            const lastResponseTimes: any = []
            const linkResponseTime: any = {
                'BFF SOAP': 'https://onenr.io/0gR7Mn6qZQo',
                'Consorcio SOAP': 'https://onenr.io/0LREqAd0nRa',
                'Devetel': 'https://onenr.io/02R5mA1E0Rb',
                'Webpay': 'https://onenr.io/0vjAYogE3jP',
            }
            Object.entries(graphResponseTimeEntities).forEach(([key, service]: any) => {
                const dataMap = new Map(
                    service.response_time?.query?.data?.actor?.account?.nrql?.results.map((entry: any) => {
                        const date = new Date(entry.beginTimeSeconds * 1000);
                        date.setSeconds(0, 0);
                        let data = null
                        if (entry['Response time']) {
                            data = entry['Response time'];
                        }

                        return [
                            date.getTime(),
                            data
                        ]
                    })
                )

                datasetsResponseTimes.push({
                    label: key,
                    data: allTimestamps.map((timestamp) => dataMap.get(timestamp) || null),
                    borderColor: getRandomColor(),
                    backgroundColor: getRandomColor(),
                    fill: false,
                    spanGaps: true
                });

                const results = service.response_time?.query?.data?.actor?.account?.nrql?.results;
                const lastResult = results[results.length - 1];
                lastResponseTimes.push({
                    name: key,
                    status: 'OK',
                    time: `${lastResult['Response time'].toFixed(2)} ms`,
                    link: linkResponseTime[key]
                })
            })
            setSoapGraphData({ labels: formattedTimestamps, datasets: datasetsResponseTimes })

            const arrayServicesTemp: any = []
            soapServices.forEach((service: any) => {
                const serviceName = service.name;
                const serviceData = apiMetrics[serviceName]
                if (serviceData) {
                    let status = 'OK'
                    const alerts = serviceData.services?.query?.data?.actor?.account?.nrql?.results;
                    alerts.forEach((alert: any) => {
                        if (alert.priority === 'warning' && status === 'OK') {
                            status = 'WARNING';
                        }
                        else if (alert.priority === 'critical') {
                            status = 'CRITICAL';
                        }

                        if (alert.hasOwnProperty('error') && alert.error) { //si tiene el atributo pero es un string vacío
                            status = 'CRITICAL'
                        }

                    });
                    arrayServicesTemp.push({ name: serviceName, status: status, link: service.link })

                } else {
                    const serviceHosts = service.host.map((host: any) => nagiosData[host])
                    let status = 'OK'

                    if (!service.serviceName) {
                        // No specific service - check if ANY service is not OK
                        const hasIssue = serviceHosts.some((host: any) => {
                            const hasCriticalService = host?.services?.some((svc: any) => {
                                return svc.state !== 0
                            })
                            return hasCriticalService;
                        })
                        if (hasIssue) status = 'CRITICAL'
                    } else {
                        // Specific service - check only that service
                        const hasIssue = serviceHosts.some((host: any) => {
                            if (!host?.services) return false
                            const svc = host.services.find((s: any) => s.description === service.serviceName)
                            return svc && svc.state !== 0
                        })
                        if (hasIssue) status = 'CRITICAL'
                    }

                    arrayServicesTemp.push({ name: serviceName, status: status, link: service.link })
                }
            })
            const arrayServicesSorted = arrayServicesTemp.sort((sv: any, sv2: any) => {
                if (sv.status === 'OK') {
                    return -1;
                }
                if (sv2.status === 'OK') {
                    return 1;
                }
                if (sv.status === 'WARNING') {
                    return -1;
                }
                else {
                    return 1;
                }
            })

            setSoapData((soapData: any) => {
                return {
                    ...soapData,
                    arrayMachineState: arrayMachineStateTemp,
                    arrayDatabaseState: arrayDatabaseSOAPTemp,
                    arrayResponseTime: lastResponseTimes,
                    arrayServiceState: arrayServicesSorted,
                    rate: rate
                }
            })

            const synthNRData = apiMetrics['Sintetico New Relic'].services.query?.data?.actor?.account?.nrql?.results;
            const synthDataset = []
            const pnpNagiosData = pnpNagiosReadSynth(data.pnp_nagios['Sintetico DVU'])
            if (pnpNagiosData && synthNRData) {
                const Timestamps = [
                    ...new Set([
                        ...synthNRData.map(
                            (entry: any) => {
                                const date = new Date(entry.timestamp);
                                date.setSeconds(0, 0);
                                return date.getTime();
                            }
                        ),
                        ...pnpNagiosData.timeStamps.map(
                            (timestamp: any) => {
                                const date = new Date(timestamp);
                                date.setSeconds(0, 0);
                                return date.getTime();
                            }
                        )

                    ])
                ].sort((a: any, b: any) => a - b);
                const formattedTimestamps2 = Timestamps.map((date: any) => {
                    const formattedTime = new Date(date);
                    const hours = formattedTime.getHours();
                    const minutes = formattedTime.getMinutes();

                    return `${hours}:${String(minutes).padStart(2, '0')}`
                })

                const dataMap = new Map(
                    synthNRData.map((entry: any) => {
                        const date = new Date(entry.timestamp);
                        date.setSeconds(0, 0);
                        const data = entry.error === '' ? 1 : 0

                        return [
                            date.getTime(),
                            data
                        ]
                    })
                )
                const nrColor = getRandomColor();
                synthDataset.push({
                    label: 'Sintetico New Relic',
                    data: Timestamps.map((timestamp) => dataMap.get(timestamp) || null),
                    borderColor: nrColor,
                    backgroundColor: nrColor,
                    fill: false,
                    spanGaps: true
                });

                const dvuColor = getRandomColor();
                synthDataset.push({
                    label: 'Sintetico DVU',
                    data: Timestamps.map((timestamp) => pnpNagiosData.rowsMap.get(timestamp) || null),
                    borderColor: dvuColor,
                    backgroundColor: dvuColor,
                    fill: false,
                    spanGaps: true
                })

                setSoapSynthData({ labels: formattedTimestamps2, datasets: synthDataset });
            }

        }  

        const arrayRelationships = options.services_relationships.split('\n').map((eachRlt: any) => {
            const arrayInfoRlt = eachRlt.split(',')
            return {
                hostname: arrayInfoRlt[0],
                serviceNagios: arrayInfoRlt[1],
                parent: arrayInfoRlt[2],
                item: arrayInfoRlt[3]
            }
        })
        const copyWraps = { ...panelWraps }
        arrayRelationships.forEach((rlt: any) => {
            const [infoState] = getStatesForServices(data, [
                { hostName: rlt.hostname, serviceDescription: rlt.serviceNagios }
            ])
            const items = copyWraps[rlt.parent].items
            const titleToUpdate = rlt.item
            const newStatusCardValue = infoState.state
            const itemToUpdate = items.find((item: any) => item.title === titleToUpdate)
            if (itemToUpdate) {
                const values: any = {
                    0: 'OK',
                    1: 'WARNING',
                    2: 'CRITICAL',
                    3: 'UNK',
                    4: 'NOTDEFINED'
                }
                itemToUpdate.status = values[newStatusCardValue]
            }
        })
        setPanelWraps(copyWraps)
    }

    useEffect(() => {
        getBackendInfo()
    }, [data])

    function togglePanel(panel: string) {
        setPanelDisplay(panel)
    }

    return (
        <div
            className='panel-wrapper'
            ref={containerRef}

            style={{
                width: `${width}px`,
                height: `${height}px`,
                minHeight: '1000px',
                minWidth: '1920px',
                backgroundColor: '#d8e1f5',
                padding: '15px'
            }}
        >
            <button
                className='custom-hide'
                onClick={panelDisplay === 'ejecutivo' ? () => togglePanel('seguros') : () => togglePanel('ejecutivo')}
            >
                <img src={icons.returnIcon} />
            </button>
            <div></div>
            <div className='view-container'>
                {panelDisplay === 'ejecutivo' && (
                    <PanelEjecutivo hosts={hostsCns} wraps={panelWraps} togglePanel={togglePanel} />
                )}
                {panelDisplay === 'seguros' && <SistemaDeSeguros flujosSeguros={flujosSeguros} />}
                {panelDisplay === 'SOAP' && <SOAP soapInfo={soapData} soapGraphData={soapGraphData} soapSynthData={soapSynthData} />}
            </div>
        </div>
    )
}
