import React, { useState, useEffect } from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import DashboardPanel from "../../components/DashboardPanel/DashboardPanel";
import StatusCard from "../../components/StatusCard/StatusCard";
import CardInfo from "../../components/CardInfo/CardInfo";
import { wraps } from "../../utils/sistema_de_seguros_data";
import "./SistemaDeSeguros.css";

function SistemaDeSeguros({ flujosSeguros, style }) {
  function divideIntoGroups(arr, groupSize) {
    const result = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      result.push(arr.slice(i, i + groupSize));
    }
    return result;
  }

  const rows = divideIntoGroups(flujosSeguros, 6);

  const content = rows.map((row) => {
    const content_row = row.map((item) => {
      return (
        <CardInfo
          title={item.name}
          status_card={item.status}
          status_card_title={item.percentage}
          style={{ width: "15%" }}
        />
      );
    });
    return <div className="a">{content_row}</div>;
  });

  const col1Style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "5px 5px",
    padding: "15px 0px",
  };

  return (
    <section className="insurance-view">
      <Toolbar title={`Sistema de Seguros`} />
      <div className="insurance-body">
        <div className="col1">
          <DashboardPanel title={"Cotizadores"} style={col1Style}>
            <StatusCard title={"%"} status={"UNK"} />
          </DashboardPanel>
          <DashboardPanel title={"Sitio web"} style={col1Style}>
            <StatusCard title={"%"} status={"UNK"} />
          </DashboardPanel>
          <DashboardPanel title={"Botón de pago"} style={col1Style}>
            <StatusCard title={"%"} status={"UNK"} />
          </DashboardPanel>
          <DashboardPanel title={"Plataforma"} style={col1Style}>
            <StatusCard title={"%"} status={"UNK"} />
          </DashboardPanel>
        </div>
        <div className="col2">
          <DashboardPanel
            title={"Monitoreo Sintético"}
            style={{ height: "100%", padding: "5px 20px" }}
          >
            {content}
          </DashboardPanel>
        </div>
      </div>
    </section>
  );
}

export default SistemaDeSeguros;
